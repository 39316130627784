import axios from '../instance'
import { ClaimListRequest, UpdateClaimStatusRequest } from './type'

export const getClaimListAPI = async (params: ClaimListRequest): Promise<any> => {
	try {
		const response = await axios.get('/admin/claim', {
			params
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateClaimStatus = async (payload: UpdateClaimStatusRequest): Promise<any> => {
	try {
		const response = await axios.put(`/admin/claim/${payload.orderId}`, payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}